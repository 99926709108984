<script>
  export default {
    name: 'layout-full',

    render() {
      return this.$slots.default[0];
    },
  };
</script>

<style lang="scss">
  .layout-full.v-application {
    background-color: c('white', 'light') !important;
  }
</style>
